import logoPath from '../../assets/logo-light.svg';
import css from './Logo.module.css';

const Logo = () => (
  <>
    <img src={logoPath} alt="Logo" width="200" height="181" />
  </>
);

export default Logo;
