import css from './NotFoundPage.module.css';

const NotFoundPage = () => {
  return (
    <>
      <h2>Page Not Found ...</h2>
    </>
  );
}

export default NotFoundPage;
