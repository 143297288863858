// Styles 
import css from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={css.footer}>
      <p className={css.footerText}>Copyright © 2024 Skiff Systems Ltd.</p>
    </footer>
  );
};

export default Footer;
